<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="票根兑换码" prop="ticketCode" >
        <a-input v-model="form.ticketCode" placeholder="请输入票根兑换码" :disabled="true" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="兑换用户" prop="userName" >
        <a-input v-model="form.userName" placeholder="请输入兑换用户" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="票根名" prop="ticketName" >
        <a-input v-model="form.ticketName" placeholder="请输入票根名" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="票根使用时间" prop="useTime" >
        <a-date-picker style="width: 100%" v-model="form.useTime" format="YYYY-MM-DD HH:mm:ss" :disabled="true" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-select style="width: 100%" v-model="form.status" placeholder="请选择状态">
          <a-select-option v-for="(item, index) in this.customDict.TicketStubCodeStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getTicketStubCode, addTicketStubCode, updateTicketStubCode } from '@/api/ticket/ticketStubCode'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        ticketCode: null,
        userId: null,
        ticketId: null,
        useTime: null,
        status: 0,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        ticketCode: [
          { required: true, message: '票根兑换码不能为空', trigger: 'blur' }
        ],
        ticketId: [
          { required: true, message: '票根id不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ],
        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        ticketCode: null,
        userId: null,
        ticketId: null,
        useTime: null,
        status: 0,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getTicketStubCode(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateTicketStubCode(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addTicketStubCode(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
