<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="票根兑换码" prop="ticketCode">
                <a-input v-model="queryParam.ticketCode" placeholder="请输入票根兑换码" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="兑换用户" prop="userName">
                <a-input v-model="queryParam.userName" placeholder="请输入兑换用户" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="票根名" prop="ticketName">
                <a-input v-model="queryParam.ticketName" placeholder="请输入票根名" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-model-item label="状态" prop="status" >
                <a-select style="width: 100%" v-model="queryParam.status" placeholder="请选择状态" allow-clear>
                  <a-select-option v-for="(item, index) in this.customDict.TicketStubCodeStatusEnum" :value="item.type" :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!-- <a-col :md="8" :sm="24">
              <a-form-item label="票根使用时间" prop="useTime">
                <a-date-picker style="width: 100%" v-model="queryParam.useTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
              </a-form-item>
            </a-col> -->
            <a-col :md="16" :sm="24">
              <span class="table-page-search-submitButtons" :style="{ float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <!--
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['ticket:stub/code:add']">
          <a-icon type="plus" />新增
        </a-button>
        -->
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['ticket:stub/code:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['ticket:stub/code:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleExport" v-hasPermi="['ticket:stub/code:export']">
          <a-icon type="download" />导出
        </a-button>
        <a-button type="primary" :disabled="multiple" @click="handleChangeStatus" v-hasPermi="['ticket:stub/code:change:status']">
          <a-icon type="interaction" />批量修改状态
        </a-button>
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        :data-source="list"
        :rowKey="(record, index) => index + 1"
        :columns="columns"
        :customRow="columnClick"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <template v-slot:useTime="text, record">
          {{ parseTime(record.useTime) }}
        </template>
        <template v-slot:createTime="text, record">
          {{ parseTime(record.createTime) }}
        </template>
        <template v-slot:status="text, record">
          <custom-dict-tag :options="customDict.TicketStubCodeStatusEnum" :value="record.status"/>
        </template>
        <template v-slot:operation="text, record">
          <a-divider type="vertical" v-hasPermi="['ticket:stub/code:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['ticket:stub/code:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['ticket:stub/code:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['ticket:stub/code:remove']">
            <a-icon type="delete" />删除
          </a>
        </template>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        :pageSizeOptions="['10', '20', '50', '100']"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>

    <a-modal
      ref="batchUpdModal"
      title="批量修改状态"
      :width="500"
      :visible="batchUpdModal"
      @ok="batchUpdStatus"
      @cancel="closeModal"
    >
      <a-form-model-item label="状态">
        <a-select style="width: 100%" v-model="status" placeholder="请选择状态">
          <a-select-option v-for="(item, index) in this.customDict.TicketStubCodeStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-modal>
  </page-header-wrapper>
</template>

<script>
import {
  listTicketStubCode,
  delTicketStubCode,
  batchChangeStatus
} from '@/api/ticket/ticketStubCode'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import CustomDictTag from '@/components/DictCustomTag/index.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'StubCode',
  components: {
    CustomDictTag,
    CreateForm
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        ticketCode: null,
        userId: null,
        userName: null,
        ticketId: null,
        ticketName: null,
        useTime: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 60,
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => index + 1
        },
        // {
        //   title: 'id',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '票根兑换码',
          dataIndex: 'ticketCode',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '兑换用户id 外键user_user.id',
        //   dataIndex: 'userId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '兑换用户',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '票根id 外键ticket_stub.id',
        //   dataIndex: 'ticketId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '票根名称',
          dataIndex: 'ticketName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '票根使用时间',
          dataIndex: 'useTime',
          scopedSlots: { customRender: 'useTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '备注',
        //   dataIndex: 'remark',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],

      batchUpdModal: false,
      status: null
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询票根兑换码列表 */
    getList () {
      this.loading = true
      listTicketStubCode(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false

        this.selectedRows = []
        this.selectedRowKeys = []
      })
    },

    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        ticketCode: null,
        userId: null,
        userName: null,
        ticketId: null,
        ticketName: null,
        useTime: null,
        status: null,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    verifyStatus() {
      this.single = this.selectedRowKeys.length !== 1
      this.multiple = !this.selectedRowKeys.length
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.verifyStatus()
    },
    columnClick(row, index) {
      return {
        on: {
          click: () => {
            if (this.selectedRowKeys.includes(index + 1)) {
              const keyIndex = this.selectedRowKeys.findIndex(i => i === index + 1)
              this.selectedRowKeys.splice(keyIndex, 1)
              this.selectedRows.splice(keyIndex, 1)
              this.ids.splice(keyIndex, 1)
              this.verifyStatus()
            } else {
              this.selectedRowKeys.push(index + 1)
              this.selectedRows.push(row)
              this.ids.push(row.id)
              this.verifyStatus()
            }
          }
        }
      }
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      const that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delTicketStubCode(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      const that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('ticket/stub/code/export', {
            ...that.queryParam
          }, `stub/code_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    },
    handleChangeStatus () {
      if (this.ids.length > 0) {
        this.batchUpdModal = true
      } else {
        this.$message.warning('请先选择数据！')
      }
    },
    batchUpdStatus () {
      if (this.status == null) {
        this.$message.warning('请选择状态再进行修改！')
      } else {
        const data = {
          ids: this.ids,
          status: this.status
        }
        batchChangeStatus(data).then(res => {
          this.$message.success(
            '批量修改状态成功！',
            3
          )
          this.getList()
          this.closeModal()
        })
      }
    },
    // 关闭模态框
    closeModal () {
      this.batchUpdModal = false
      this.status = null
    }
  }
}
</script>
